import React, { Component, Fragment } from 'react';
import loadable from '@loadable/component';

const TopBar = loadable(() => import('common/blocks/topBar'));
const Header = loadable(() => import('common/blocks/header'));
const Footer = loadable(() => import('common/blocks/footer'));
const CookieConsent = loadable(() =>
  import('common/blocks/functional/cookieConsent')
);
const RichSnippet = loadable(() =>
  import('common/components/_partials/RichSnippet')
);

class FoodfactoryLayout extends Component {
  render() {
    const { children } = this.props;
    return (
      <Fragment>
        <TopBar />
        <Header />
        <main data-id="content">{children}</main>
        <Footer />
        <CookieConsent />
        <RichSnippet socials={[]} />
        <RichSnippet type={'search'} />
      </Fragment>
    );
  }
}

export default FoodfactoryLayout;
